import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiNotifications } from '../src';
import { NotificationsExample } from './example';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uinotifications"
    }}>{`UiNotifications`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Used to render notifications on the top right corner of the screen, in small breakpoints the notifications appear at the bottom of the screen.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/notifications`}</p>
    </blockquote>
    <h2 {...{
      "id": "how-to-use"
    }}>{`How to use?`}</h2>
    <ol>
      <li parentName="ol">{`Render the component `}<inlineCode parentName="li">{`<UiNotifications />`}</inlineCode>{` inside the `}<inlineCode parentName="li">{`<UiView />`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Use the hook `}<inlineCode parentName="li">{`useNotifications()`}</inlineCode>{` to retrieve the function `}<inlineCode parentName="li">{`showNotification()`}</inlineCode>{` which allows you to add the notification content.`}</li>
      <li parentName="ol">{`Once the notification is shown, the timeout will start running and will automatically close the notification after 5 secs.`}</li>
    </ol>
    <h2 {...{
      "id": "usenotifications-hook"
    }}>{`useNotifications hook`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`useNotifications`}</inlineCode>{` hook gives 2 values:`}</p>
    <ol>
      <li parentName="ol">{`notifications - `}<inlineCode parentName="li">{`UiNotifications[]`}</inlineCode></li>
      <li parentName="ol">{`showNotification - `}<inlineCode parentName="li">{`(notification: UiNotification) => void;`}</inlineCode></li>
    </ol>
    <h2 {...{
      "id": "uinotification-datatype"
    }}>{`UiNotification datatype`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/types/notifications.ts"
      }}>{`UiNotification`}</a>{` type has this structure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`type UiNotificationLink = {
  label: string;
  url: string;
  target?: string;
};

type UiNotification = {
  icon?: string;
  title?: string;
  message?: string;
  link?: UiNotificationLink;
  options?: UiNotificationOptions;
};

type UiNotificationOptions = {
  category?: ColorCategory;
  timer?: number;
  closeButton?: boolean;
};
`}</code></pre>
    <p>{`Make sure you look at the `}<a parentName="p" {...{
        "href": "./packages-icons-docs-docs"
      }}>{`UiIcons`}</a>{` doc to check the available icons.`}</p>
    <h2 {...{
      "id": "uinotificationoptions"
    }}>{`UiNotificationOptions`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`category`}</inlineCode>{` The notification's theme color. Default is `}<inlineCode parentName="li">{`primary`}</inlineCode>{`.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`timer`}</inlineCode>{` The time that it will take for the notification to be automatically removed. If is 0 then it won't be removed automatically. Default is `}<inlineCode parentName="li">{`5000`}</inlineCode>{` ms.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`closeButton`}</inlineCode>{` If the close button should render. Default is `}<inlineCode parentName="li">{`true`}</inlineCode>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`The notifications provider is set up automatically by the `}<a parentName="p" {...{
        "href": "./packages-view-docs-view"
      }}>{`UiView`}</a>{` so make sure you are using a UiView component to wrap your application.`}</p>
    <Playground __position={2} __code={'<div style={{ height: \'300px\' }}>\n  <NotificationsExample />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiNotifications,
      NotificationsExample,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div style={{
        height: '300px'
      }}>
    <NotificationsExample mdxType="NotificationsExample" />
  </div>
    </Playground>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const NotificationsExample: React.FC = () => {
  const { showNotification } = useNotifications();

  const addNotification = useCallback(() => {
    showNotification({
      icon: 'Check',
      title: 'New notification',
      message: 'This is a new notification',
    });
  }, [showNotification]);

  const addLinkNotification = useCallback(() => {
    showNotification({
      icon: 'Link',
      title: 'Notification with link',
      message: 'This is a new notification that shows a link',
      link: {
        label: 'Ui React docs',
        url: 'https://uireact.io',
      },
    });
  }, [showNotification]);

  return (
    <>
      <UiNotifications />
      <UiFlexGrid columnGap="four">
        <UiButton onClick={addNotification} category="positive">
          <UiSpacing padding={buttonContentPadding}>
            <UiText>Add notification</UiText>
          </UiSpacing>
        </UiButton>
        <UiButton onClick={addLinkNotification} category="tertiary">
          <UiSpacing padding={buttonContentPadding}>
            <UiText>Add a notification with link</UiText>
          </UiSpacing>
        </UiButton>
      </UiFlexGrid>
    </>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiNotifications} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      